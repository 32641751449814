.photo-series-wrapper {
  padding-top: 140px;
  padding-bottom: 88px;
}

.top-info-columns {
  width: 100%;
  justify-content: space-between;
  padding: 0px 40px;
  position: relative;
  z-index: 10;

  @media (max-width: 800px) {
    padding: 0px 20px;
  }

  @media (max-width: 680px) {
    display: block;
    margin-bottom: 24px;
  }

  .top-left-column {
    width: 50%;

    @media (max-width: 680px) {
      margin-bottom: 56px;
      width: 100%;
    }
  }

  .top-right-column {
    width: 50%;
    margin-left: calc(50% - 42px);
  }
}

.series-title-wrapper {
  margin: 32px 0;

  h1 {
    font-size: 160px;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.025em;
    font-family: 'Ogg-Roman';
    transform: rotateX(90deg) rotate(12deg) translateZ(0) translateY(200px);
    transform-origin: center;
    transform-style: preserve-3d;
    will-change: transform;

    @media (max-width: 1096px) {
      font-size: 14.599vw;
    }

    @media (max-width: 680px) {
      line-height: 1;
    }

    @media (max-width: 414px) {
      font-size: 52px;
    }
  }
}

.top-right-column-inner {
  max-width: 400px;
  width: 100%;
}

.series-info-wrapper {
  max-width: 440px;
  width: 100%;
  margin-top: 72px;

  @media (max-width: 680px) {
    margin-top: 24px;
    max-width: unset;
  }
}

.photo-wrapper {
  padding: 0 40px;
  margin-top: 56px;
  width: 100%;
  position: relative;
  transition: 1s cubic-bezier(0.8, 0, 0.2, 1);

  @media (max-width: 800px) {
    padding: 0px 20px;
  }

  @media (max-width: 414px) {
    margin-top: 16px;
    padding: 0;
  }

  .photo-wrapper-inner {
    position: relative;
  }

  img {
    width: 100%;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .image-expand {
    cursor: url("/images/maximize.svg"), auto;
  }

  .reverse-max {
    cursor: url("/images/minimize.svg"), auto;
  }
}

.next-series-wrapper {
  position: relative;
  z-index: 10;
  display: flex;
  padding: 200px 40px 148px;
  min-height: 100vh;

  .series-index {
    width: calc(50% - 42px);
  }

  @media (max-width: 800px) {
    padding: 104px 20px 48px;
    min-height: auto;
  }

  @media (max-width: 640px) {
    display: block;
  }

  .next-series-label {
    font-size: 17px;
    display: table;
    letter-spacing: -0.035em;

    @media (max-width: 640px) {
      font-size: 15px;
    }
  }

  .next-series-who-wrapper {
    width: 50%;
    margin-top: -32px;
    align-items: center;
    position: relative;

    @media (max-width: 640px) {
      margin-top: 40px;
      margin-left: 32px;
      width: auto;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        transition: 0.35s ease-out;
        position: relative;
      }

      &:hover li{
        opacity: 0.2;

        @media (max-width: 1024px) {
          transition: none;
          opacity: 1;
        }
      }

      li:hover{
        opacity: 1;
        z-index: 1001;

        @media (max-width: 1024px) {
          transition: none;
          opacity: 1;
        }
      }

      li:hover .next-series-image {
        opacity: 1;
        transform: translateY(0) rotate(0deg);
        transition-delay: 0.15s;
      }
    }
  }

  .next-series-who {
    font-size: 72px;
    cursor: pointer;
    display: table;
    font-weight: 600;
    font-family: 'Ogg-Roman', "neue-haas-unica", Helvetica Neue, Helvetica, Arial;;
    position: absolute;
    transition: 0.5s ease-out;

    @media (max-width: 1024px) {
      font-size: 7.031vw;
    }

    @media (max-width: 680px) {
      font-size: 40px;
    }
  }
}

.animation-link-wrapper {
  &:before {
    counter-increment: counter;
    content: "0" counter(counter);
    position: absolute;
    font-family: "neue-haas-unica", Helvetica Neue, Helvetica, Arial;
    font-size: 12px;
    left: -32px;
    top: 8px;
    letter-spacing: 0;
  }
}

.counter-inc-wrapper {
  padding-left: 32px;
  margin-left: -32px;
  position: relative;
  height: 101px;

  @media (max-width: 1024px) {
    height: 9.863vw;
  }

  @media (max-width: 680px) {
    height: 56px;
  }

  .animation-link-wrapper {
    height: 101px;
    top: 100%;
    position: relative;

    @media (max-width: 1024px) {
      height: 9.863vw;
    }

    @media (max-width: 680px) {
      height: 56px;
    }
  }

  .scnd-next-link {
    opacity: 0;
    transform: rotateX(90deg) rotate(12deg) translateZ(0) translateY(40px);

    @media (max-width: 414px) {
      display: none;
    }
  }

  &:hover {
    .first-next-link {
    opacity: 0;
    transform: rotateX(90deg) rotate(12deg) translateZ(0) translateY(-40px);

    @media (max-width: 414px) {
      opacity: 1;
      transform: none;
    }
    }

    .scnd-next-link {
      opacity: 1;
      transform: rotateX(0deg) rotate(0deg) translateZ(0) translateY(0);
    }
  }
}

.image-expand {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;

  @media (max-width: 414px) {
    display: none;
  }
}


/* NEXT IMAGES */

.next-series-image {
  height: 340px;
  width: 280px;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: 0.35s ease-out;
  position: absolute;
  left: -65%;
  top: -80px;
  z-index: -1;
  transform: rotate(4deg) translateY(40px);

  @media (max-width: 1024px) {
    display: none;
  }
}

.next-image-one {
  background-image: url("/images/series/domesticnoir/yellow_1.jpg");
  background-size: cover;
}

.next-image-two {
  background-image: url("/images/series/daylight/daylight_2.jpg");
  background-size: cover;
}

.next-image-three {
  background-image: url("/images/series/moody/moody_1.jpg");
  background-size: cover;
}

.next-image-four {
  background-image: url("/images/series/dark/dark_1.jpg");
  background-size: cover;
}

.next-image-five {
  background-image: url("/images/series/gritty/gritty_1.jpg");
  background-size: cover;
}

.next-image-six {
  background-image: url("/images/series/burns/burns_3_1.jpg");
  background-size: cover;
}


/* DARK ROOM */
  .room-switch {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    color: #000;
    padding: 12px 24px;
    border-radius: 10em;
    border: none;
    font-weight: bold;
    cursor: pointer;
    z-index: 9999;
    outline: none;
  }

  .black-curtain {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    z-index: 9998;
    opacity: 0;
    display: none;
  }

  .hori-scroll {
    display: flex;
    height: 100vh;
  }

  .stack-vertical {
    flex-direction: column;
  }
