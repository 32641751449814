/* OVERLAY STYLES */
.overlay-wrap {
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	display: none;

	.overlay-inner-wrapper {
		position: relative;
		z-index: 100;
		display: flex;

		@media (max-width: 1080px) {
			margin-left: 88px;
		}

		@media (max-width: 768px) {
			margin-left: 48px;
		}
	}

	.dark-overlay {
		background: #fff;
		height: 100%;
		width: 100%;
		position: fixed;
		opacity: 0;
	}

	.color-element {
		height: 100%;
		width: 100%;
		position: fixed;
	}

	.place-cut-transitions {
		position: absolute;
		top: 144px;
		right: 80px;
		font-size: 15px;
		text-transform: uppercase;
		font-weight: 500;

		@media (max-width: 680px) {
			right: 20px;
		}
	}
}
