/* GENERAL STYLES */

* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-kerning: auto;
  font-kerning: auto;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html {
  background: #fff;
}

body {
  font-size: 13px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  font-family: "neue-haas-unica", Helvetica Neue, Helvetica, Arial;
  color: #000;
  background: #fff;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  font-style: normal;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  overscroll-behavior-y: none;
  counter-reset: counter;
}

@font-face {
  font-family: 'Ogg-Roman';
  src: url('/fonts/Ogg-Roman.eot');
  src: url('/fonts/Ogg-Roman.woff') format('woff');
}

a {
  text-decoration: none;
  color: #000;
  outline: none;

  :hover {
    color: #000;
    text-decoration: none;
  }
}

p {
  margin: 0;
}

h1, h2, h3, h4, h5 {
  margin: 0;
  letter-spacing: -0.025em;
}


::selection {
  background-color: #000;
}


/* USED TO MOVE ANIMATIONS IN OVERFLOW */

.overflow-hidden {
  overflow: hidden;
}

.moved-top {
  transform: translateY(105%);
}

.fade-in {
  transform: translateY(32px);
  opacity: 0;
}

.mark-bold {
  font-weight: 600;
}

.bottom-16 {
  margin-bottom: 16px;
}

.bottom-8 {
  margin-bottom: 8px;
}
