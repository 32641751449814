.mobile-error-wrapper {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }

  .mobile-404-wrapper {
    font-size: 180px;
    text-align: center;
    font-weight: bold;
    margin-top: 15vh;

    @media (max-width: 414px) {
      font-size: 43.478vw;
    }
  }
}

.error-mask {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

    text {
      font-size: 46.5vw;
      font-weight: 900;
      text-align: center;
      letter-spacing: -0.025em;
      transform: translateX(8%) translateY(80vh);
      position: relative;

      @media (min-width: 1439px) and (max-width: 1440px) {
        transform: translateX(8%) translateY(75vh);
      }

      @media (max-width: 680px) {
      transform: translateX(8%) translateY(60vh);
      }
  }
}

video {
  height: 100vh;
  width: 120vw;
  position: absolute;
  top: 0;
  left: -10vw;
  z-index: 0;
  background: #000;

  @media (max-width: 680px) {
  width: 100vw;
  left: 0;
  }
}

.hide-overflow {
  overflow-y: hidden;
}

#masked {
  mask: url("#maskLayer");
}
