.nav-wrapper {
  position: fixed;
  top: 40px;
  left: 40px;
  right: 40px;
  width: calc(100% - 80px);
  z-index: 9002;
  color: #fff;
  mix-blend-mode: difference;

  @media (max-width: 800px) {
    top: 20px;
    left: 20px;
    right: 20px;
    width: calc(100% - 40px);
  }

.top-nav {
  display: flex;
  justify-content: space-between;

  .top-left {
    display: flex;
    font-weight: 500;

    p {
      cursor: pointer;
    }

    .overflow-hidden:nth-child(1) {
      margin-right: 24px;
      font-weight: bold;

      @media (max-width: 414px) {
        margin-right: 24px;
      }
    }
  }

  .top-right {
    display: flex;
  }

  a {
    color: #fff;
    display: block;
    cursor: pointer;
    font-weight: 600;

      &:after {
        content: "";
        position: absolute;
        z-index: 0;
        right: 0;
        width: 0;
        bottom: 0;
        background: #fff;
        height: 1px;
        transition-property: width;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
    }

    &:hover:after,
    &:focus:after,
    &:active:after {
      left: 0;
      right: auto;
      width: 100%;
    }
  }
}
}


.bottom-info {
  position: absolute;
  bottom: 40px;
  left: 40px;
  right: 40px;
  z-index: 10;
  width: calc(100% - 80px);
  display: flex;
  align-items: baseline;
  color: #fff;
  font-size: 12px;
  mix-blend-mode: difference;

  @media (max-width: 680px) {
    bottom: 20px;
    right: 20px;
    left: 20px;
    width: calc(100% - 40px);
  }

  .bottom-left {
    display: flex;

    .overflow-hidden:nth-child(1) {
      margin-right: 32px;

      @media (max-width: 414px) {
        margin-right: 24px;
      }
    }
  }

  .bottom-center {
    margin-left: calc(50% - 114px);

    @media (max-width: 680px) {
      display: none;
    }
  }

  .bottom-right {
    position: absolute;
    right: 0;
      img {
        height: 16px;
        padding-top: 3px;
      }
  }
}

.mobile-link {
  display: none;

  @media (max-width: 680px) {
    display: block;
  }
}

.desktop-link {
  @media (max-width: 680px) {
    display: none;
  }
}

.info-wrapper-outer {
  z-index: 9800;
  position: fixed;
  top: 40px;
  left: calc(50% - 42px);
  mix-blend-mode: difference;
  color: #fff;

  @media (max-width: 680px) {
    top: 20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 85px;
  }
}

.more-info-wrapper {
  display: flex;
  cursor: pointer;

  .eye-wrapper {
    margin-right: 8px;
    cursor: pointer;
  }

    .more-less {
      cursor: pointer;

    &:before {
      content: 'More ';
      position: relative;
      display: inline;
    }
  }

  .less-more {
    &:before {
      content: 'Less ';
      position: relative;
      display: inline;
    }
  }
}

.pupil {
  animation-name: look;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  position: absolute;
}

// @keyframes look {
//   0% {
//     left: 0;
//   }
//
//   33% {
//     transform: translateX(-1px);
//   }
//
//   66% {
//     transform: translateX(1px);
//   }
//
//   100% {
//     left: 0;
//   }
// }

.menu-fixed {
  position: fixed;
  top: 40px;
  left: 40px;
  right: 40px;
  width: calc(100% - 80px);
  z-index: 0;
  height: calc(100vh - 88px);

  @media (max-width: 680px) {
    left: 0;
    right: 0;
    top: 0;
    height: 100vh;
    overflow-y: auto;
    padding: 20px;
    width: 100%;
  }
}

.menu-z-high {
  z-index: 9001;
}

.menu-expander {
  height: 0;
  width: 0;
  top: 46px;
  background: #000;
  border-radius: 999em;
  left: calc(50% + -35px);
  transform-origin: center;
  position: fixed;
  transition: 0.65s cubic-bezier(0.5, 0, 0.2, 1);
  z-index: 9001;

  @media (max-width: 414px) {
    top: 29px;
    left: calc(50% - 32px);
  }
}

.menu-active {
  height: 1600px;
  width: 1600px;
  left: -12%;
  top: -50%;

  @media (min-width: 1441px) {
    height: 2500px;
    width: 2500px;
    left: -15%;
    top: -50%;
  }

  @media (min-width: 1281px) and (max-width: 1440px) {
    height: 1800px;
    width: 1800px;
    left: -12%;
  }

  @media (max-width: 1220px) {
    left: -15%;
  }

  @media (max-width: 980px) {
    height: 1400px;
    width: 1400px;
    left: -20%;
  }

  @media (max-width: 414px) {
    height: 1000px;
    width: 1000px;
    left: -80%;
    top: -10%;
  }
}

.menu-wrapper {
  padding-top: 104px;
  color: #fff;
  z-index: 9002;
  position: relative;

  @media (min-width: 1441px) {
    padding-top: 180px;
  }

  .menu-column-wrapper {
    display: flex;

    @media (max-width: 680px) {
      display: block;
    }
  }

  .menu-column-left {
    width: calc(50% - 42px);

    @media (max-width: 680px) {
      margin-bottom: 56px;
      width: 100%;
    }
  }

  .menu-column-center {
    width: 25%;

    @media (max-width: 680px) {
      display: inline-block;
      width: 50%;
    }
  }

  .menu-column-right {
    margin-left: auto;
    text-align: right;

    @media (max-width: 680px) {
      display: inline-block;
      width: 50%;
    }
  }

  .menu-v-stack {
    margin-bottom: 48px;

    @media (max-width: 680px) {
      margin-bottom: 32px;
    }
  }

  .small-menu-titles {
    font-size: 13px;
    font-weight: bold;
    display: block;
  }

  .camera-wrapper {
    margin-left: -10px;
  }

  .camera-type {
    font-size: 120px;
    line-height: 0.85;
    letter-spacing: -0.025em;
    display: block;

    @media (max-width: 920px) {
      font-size: 72px;
    }
  }

  .series-type {
    font-size: 13px;
  }

  .small-series-title {
    font-size: 36px;
    font-family: 'Ogg-Roman';

    @media (max-width: 920px) {
      font-size: 24px;
    }
  }
}

.left-aligner {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9999;
  height: 16px;

  @media (max-width: 680px) {
    left: 20px;
    bottom: 20px;
  }
}

.right-aligner {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 9999;
  height: 16px;

  @media (max-width: 680px) {
    right: 20px;
    bottom: 20px;
  }
}

.aligned-moved-top {
  transform: translateY(110%);
}
