.master-series-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  max-width: 1100px;
  opacity: 0;
  height: 300px;
  width: 100%;
}

.series-1 {
  .master-series-bg {
    background-image: url("/images/series/domesticnoir/still.jpg");
  }

  .preview-one {
    .preview-image-bg {
      background-image: url("/images/series/domesticnoir/previews/preview_1.jpg");
    }
  }

  .preview-two {
    transition-delay: 0.05s;
    transform: translateX(220px);
      .preview-image-bg {
        background-image: url("/images/series/domesticnoir/previews/preview_2.jpg");
      }
    }

  .preview-three {
    transition-delay: 0.1s;
    transform: translateX(440px);
      .preview-image-bg {
        background-image: url("/images/series/domesticnoir/previews/preview_3.jpg");
      }
    }

  .preview-four {
    transition-delay: 0.15s;
    transform: translateX(660px);
      .preview-image-bg {
        background-image: url("/images/series/domesticnoir/previews/preview_4.jpg");
      }
    }

  .preview-five {
    transition-delay: 0.2s;
    border-right: none;
    transform: translateX(880px);
      .preview-image-bg {
        background-image: url("/images/series/domesticnoir/previews/preview_5.jpg");
      }
    }
}

.series-2 {
  .master-series-bg {
    background-image: url("/images/series/daylight/still.jpg");
  }

  .preview-one {
    .preview-image-bg {
      background-image: url("/images/series/daylight/previews/preview_1.jpg");
    }
  }

  .preview-two {
    transition-delay: 0.05s;
    transform: translateX(220px);
      .preview-image-bg {
        background-image: url("/images/series/daylight/previews/preview_2.jpg");
      }
    }

  .preview-three {
    transition-delay: 0.1s;
    transform: translateX(440px);
      .preview-image-bg {
        background-image: url("/images/series/daylight/previews/preview_3.jpg");
      }
    }

  .preview-four {
    transition-delay: 0.15s;
    transform: translateX(660px);
      .preview-image-bg {
        background-image: url("/images/series/daylight/previews/preview_4.jpg");
      }
    }

  .preview-five {
    transition-delay: 0.2s;
    border-right: none;
    transform: translateX(880px);
      .preview-image-bg {
        background-image: url("/images/series/daylight/previews/preview_5.jpg");
      }
    }
}

.series-3 {
  .master-series-bg {
    background-image: url("/images/series/moody/still.jpg");
  }

  .preview-one {
    .preview-image-bg {
      background-image: url("/images/series/moody/previews/preview_1.jpg");
    }
  }

  .preview-two {
    transition-delay: 0.05s;
    transform: translateX(220px);
      .preview-image-bg {
        background-image: url("/images/series/moody/previews/preview_2.jpg");
      }
    }

  .preview-three {
    transition-delay: 0.1s;
    transform: translateX(440px);
      .preview-image-bg {
        background-image: url("/images/series/moody/previews/preview_3.jpg");
      }
    }

  .preview-four {
    transition-delay: 0.15s;
    transform: translateX(660px);
      .preview-image-bg {
        background-image: url("/images/series/moody/previews/preview_4.jpg");
      }
    }

  .preview-five {
    transition-delay: 0.2s;
    border-right: none;
    transform: translateX(880px);
      .preview-image-bg {
        background-image: url("/images/series/moody/previews/preview_5.jpg");
      }
    }
}

.series-4 {
  .master-series-bg {
    background-image: url("/images/series/dark/still.jpg");
  }

  .preview-one {
    .preview-image-bg {
      background-image: url("/images/series/dark/previews/preview_1.jpg");
    }
  }

  .preview-two {
    transition-delay: 0.05s;
    transform: translateX(220px);
      .preview-image-bg {
        background-image: url("/images/series/dark/previews/preview_2.jpg");
      }
    }

  .preview-three {
    transition-delay: 0.1s;
    transform: translateX(440px);
      .preview-image-bg {
        background-image: url("/images/series/dark/previews/preview_3.jpg");
      }
    }

  .preview-four {
    transition-delay: 0.15s;
    transform: translateX(660px);
      .preview-image-bg {
        background-image: url("/images/series/dark/previews/preview_4.jpg");
      }
    }

  .preview-five {
    transition-delay: 0.2s;
    border-right: none;
    transform: translateX(880px);
      .preview-image-bg {
        background-image: url("/images/series/dark/previews/preview_5.jpg");
      }
    }
}

.series-5 {
  .master-series-bg {
    background-image: url("/images/series/gritty/still.jpg");
  }

  .preview-one {
    .preview-image-bg {
      background-image: url("/images/series/gritty/previews/preview_1.jpg");
    }
  }

  .preview-two {
    transition-delay: 0.05s;
    transform: translateX(220px);
      .preview-image-bg {
        background-image: url("/images/series/gritty/previews/preview_2.jpg");
      }
    }

  .preview-three {
    transition-delay: 0.1s;
    transform: translateX(440px);
      .preview-image-bg {
        background-image: url("/images/series/gritty/previews/preview_3.jpg");
      }
    }

  .preview-four {
    transition-delay: 0.15s;
    transform: translateX(660px);
      .preview-image-bg {
        background-image: url("/images/series/gritty/previews/preview_4.jpg");
      }
    }

  .preview-five {
    transition-delay: 0.2s;
    border-right: none;
    transform: translateX(880px);
      .preview-image-bg {
        background-image: url("/images/series/gritty/previews/preview_5.jpg");
      }
    }
}

.series-6 {
  .master-series-bg {
    background-image: url("/images/series/burns/still.jpg");
  }

  .preview-one {
    .preview-image-bg {
      background-image: url("/images/series/burns/previews/preview_1.jpg");
    }
  }

  .preview-two {
    transition-delay: 0.05s;
    transform: translateX(220px);
      .preview-image-bg {
        background-image: url("/images/series/burns/previews/preview_2.jpg");
      }
    }

  .preview-three {
    transition-delay: 0.1s;
    transform: translateX(440px);
      .preview-image-bg {
        background-image: url("/images/series/burns/previews/preview_3.jpg");
      }
    }

  .preview-four {
    transition-delay: 0.15s;
    transform: translateX(660px);
      .preview-image-bg {
        background-image: url("/images/series/burns/previews/preview_4.jpg");
      }
    }

  .preview-five {
    transition-delay: 0.2s;
    border-right: none;
    transform: translateX(880px);
      .preview-image-bg {
        background-image: url("/images/series/burns/previews/preview_5.jpg");
      }
    }
}
