.content-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: manipulation;
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: manipulation;
}

.object-stay-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .series-pre {
    font-size: 9px;
    margin-bottom: 346px;
    text-transform: uppercase;
    margin-top: -24px;

    @media (max-width: 414px) {
      margin-bottom: 280px;
      margin-top: 0;
    }
  }

  .hidden-numbers {
    margin-top: -8px;
  }

  .project-title {
    font-weight: 600;
    line-height: 1.35;
  }

  .project-counter {
    height: 18px;
    overflow: hidden;
  }
}

.hidden-titles {
  font-size: 64px;
  height: 128px;
  overflow: hidden;
  margin-bottom: 8px;
  cursor: pointer;
  position: absolute;
  color: #fff;
  font-family: 'Ogg-Roman', "neue-haas-unica", Helvetica Neue, Helvetica, Arial;;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);

  &:hover ~ .series-bg-overview .intro .series .master-series-container .preview-image-wrapper .preview-image,
  &:focus ~ .series-bg-overview .intro .series .master-series-container .preview-image-wrapper .preview-image,
  &:active ~ .series-bg-overview .intro .series .master-series-container .preview-image-wrapper .preview-image {
    bottom: 0;
    top: auto;
    height: 100%;

    @media (max-width: 680px) {
      display: none;
    }
  }

  &:hover ~ .master-series-container .preview-image-wrapper .preview-image,
  &:focus ~ .master-series-container .preview-image-wrapper .preview-image,
  &:active ~ .master-series-container .preview-image-wrapper .preview-image {
    bottom: 0;
    top: auto;
    height: 100%;

    @media (max-width: 680px) {
      display: none;
    }
  }

  @media (max-width: 840px) {
    font-size: 7.619vw;
    height: 15.238vw;
  }
}

.scroll-wrapper {
  padding-top: 40px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  display: none;

  &:after {
    content: 'Scroll for next';
  }

  @media (max-width: 414px) {
    &:after {
      content: 'Swipe for next';
    }
  }
}

.toggle-wrapper {
  position: fixed;
  bottom: 24px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;

  @media (max-width: 680px) {
    display: none;
  }

  div {
    display: flex;
  }

  .toggle-switch {
    position: relative;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transform: translateY(24px) rotate(32deg);
    opacity: 0;

    &:first-child {
      margin-right: 4px;
    }
  }

  svg {
    z-index: 1;
  }

  .click-switch {
    z-index: 1001;
    height: 40px;
    width: 40px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.roll-wrapper {
  .toggle-wrapper {
    .swipe-series {
      &:first-child:after {
        content: '';
        background: #000;
        height: 4px;
        width: 4px;
        border-radius: 10px;
        position: absolute;
        bottom: 0px;
      }
      }
    }
  }

.series-bg-wrapper {
  max-width: 1100px;
  height: 300px;
  width: 100%;
  position: relative;
  overflow: hidden;
  transform: translateY(-16px);
  cursor: pointer;

  @media (max-width: 414px) {
    max-width: 375px;
    height: 240px;
  }

  .master-series-container {
    position: relative;
    cursor: pointer;
  }

  .master-series-container {
    height: 300px;
    max-width: 1100px;
    width: 100%;

    @media (max-width: 414px) {
      max-width: 375px;
      height: 240px;
    }
  }

  .intro {
    width: 6600px;
    display: flex;
    transform: translateX(-5500px);

    @media (max-width: 414px) {
      width: 2250px;
      transform: translateX(0);
    }
  }
}

.series {
    position: relative;
    height: 300px;
    max-width: 1100px;
    width: 100%;
}

.preview-image-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .preview-image {
    width: 220px;
    display: block;
    border-right: 4px solid #fff;
    position: absolute;
    display: block;
    z-index: 0;
    height: 0;
    top: 0;
    left: 0;
    background: #fff;
    transition-property: height;
    transition-duration: 0.95s;
    transition-timing-function: cubic-bezier(0.5, 0, 0.2, 1);
    background: #000;
    overflow: hidden;

    .preview-image-bg {
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
      height: 300px;
      width: 220px;
    }
  }

  &:hover .preview-image,
  &:focus .preview-image,
  &:active .preview-image {
    bottom: 0;
    top: auto;
    height: 100%;

    @media (max-width: 680px) {
      display: none;
    }
  }

  .preview-mobile {
    @media (min-width: 681px) {
      display: none;
    }

    @media (max-width: 680px) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100;
    }
    .preview-image-mobile {
      height: 300px;
      width: 100%;
    }
  }
}

.overview-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 180px;

    @media (max-width: 680px) {
      padding-top: 112px;
      padding-left: 20px;
      padding-right: 20px;
    }

  .toggle-wrapper {
    .stacks-series {
        &:last-child:after {
          content: '';
          background: #000;
          height: 4px;
          width: 4px;
          border-radius: 10px;
          position: absolute;
          bottom: 0px;
        }
    }
  }

  .master-stacked-container {
    max-width: 1100px;
    width: 100%;
    margin-bottom: 104px;
  }

  .master-series-bg {
      position: relative;
      height: 300px;

      @media (max-width: 680px) {
        height: 180px;
      }
  }

  .series {
    margin-bottom: 172px;

    @media (max-width: 680px) {
      height: 180px;
      margin-bottom: 128px;
    }
  }

  .series-title-overview-wrapper {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 80px;

    @media (max-width: 680px) {
      margin-bottom: 40px;
      margin-top: 8px;
    }

    h2 {
      font-size: 36px;
      font-weight: 600;

      @media (max-width: 680px) {
        font-size: 32px;
      }
    }
  }
}

.key-number-wrapper {
  position: absolute;
    bottom: 8px;
    right: 16px;
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    display: flex;
    align-items: baseline;
    height: 40px;
    letter-spacing: -0.045em;
}

.corkscrew-titles {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  transform: rotateX(90deg) rotate(12deg) translateZ(0) translateX(40px);
}
