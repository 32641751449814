.preload-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.preloader-inner-wrapper {
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
  max-width: 260px;
  width: 100%;

  p {
    margin: 0;
    font-style: italic;
    font-family: "freight-text-pro";
  }

  span {
    transform: translateY(-3px);
    display: inline-block;
  }
}

.preload-background {
  background: #000;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.white-text {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  p {
    color: #fff;
  }
}

.black-text-push {
  z-index: 10;
  position: relative;
}

.black-text {
  z-index: 10;

  p {
    z-index: 10;
  }

  .overflow-wrapper {
    overflow: hidden;
    height: 24px;
    width: 260px;
    position: relative;
  }

  .white-rec {
    height: 24px;
    width: 250px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    position: absolute;
    z-index: 0;
    top: 1px;
  }
}




/* HOME */

.main-screen {
  height: 100vh;
  width: 100vw;
  z-index: 0;
  position: relative;
  color: #fff;
  font-size: 12px;

  .nav-wrapper {
    color: #fff;
  }
}

.home-image-wrapper {
  position: relative;
  z-index: 0;
  height: 100vh;

  .home-image {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    background-image: url("/images/lp_1.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
  }
}

.grid {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 40px;
  left: 40px;
  right: 40px;
  z-index: 1;

  @media (max-width: 800px) {
    display: block;
    bottom: 20px;
    left: 20px;
    right: 20px;
  }

  .grid-left {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 800px) {
      .overflow-hidden {
        display: none;
      }

      margin-bottom: 32px;
    }

    .narrating-wrapper {
      margin-top: 40px;

      .overflow-hidden {
        margin-bottom: -8px;
      }

      @media (max-width: 800px) {
        .overflow-hidden {
          display: block;
        }
      }

      .narrating {
        font-size: 21px;
        font-weight: 500;
        letter-spacing: -0.015em;

        @media (max-width: 680px) {
          font-size: 19px;
        }
      }
    }
  }

  .grid-right {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50vh;

    @media (max-width: 1280px) {
      width: 480px;
    }

    @media (max-width: 800px) {
      width: 100%;
      height: 35vh;
    }

    .flex-it {
      display: flex;
      margin-left: -8px;

      @media (max-width: 680px) {
        margin-left: 0;
      }
    }

    h1 {
      font-size: 190px;
      font-weight: 900;
      text-transform: uppercase;
      line-height: 190px;
      margin-left: -4px;

      @media (min-width: 1441px) {
        line-height: 14vw;
        font-size: 15vw;
      }

      @media (max-width: 800px) {
        font-size: 25vw;
        line-height: 25vw;
      }
    }

    .margin-minus {
      margin-top: -2px;
    }

    .japanese {
      font-size: 17px;
    }

    .grid-right-bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .asterix-wrapper {
        height: 18px;
      }
    }
  }
}


/* ALT BACKGROUNDS */

.background-bar-wrapper {
  position: relative;
  z-index: 100;
}

.background-bar {
  width: 10%;
  height: 90vh;
  top: 10%;
  position: relative;
  bottom: 0;
  z-index: 9999;
  display: inline-block;
  visibility: hidden;
}

.different-backgrounds {
 position: absolute;
 top: 0;
 right: 0;
 left: 0;
 bottom: 0;
 z-index: 0;
 height: 90vh;
 width: 100vw;
 z-index: 0;
}

.alt-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-size: cover;
  background-position: center;
  width: 100vw;
  visibility: hidden;
}

.alt-background-one {
  background-image: url("/images/lp_1.jpg");
}

.alt-background-two {
  background-image: url("/images/series/domesticnoir/yellow_1.jpg");
}

.alt-background-three {
  background-image: url("/images/series/domesticnoir/yellow_5.jpg");
}

.alt-background-four {
  background-image: url("/images/series/daylight/daylight_2.jpg");
}

.alt-background-five {
  background-image: url("/images/series/daylight/daylight_2_2.jpg");
}

.alt-background-six {
  background-image: url("/images/series/moody/moody_1.jpg");
}

.alt-background-seven {
  background-image: url("/images/series/dark/dark_1.jpg");
}

.alt-background-eight {
  background-image: url("/images/series/domesticnoir/yellow_9.jpg");
}

.alt-background-nine {
  background-image: url("/images/series/gritty/gritty_5.jpg");
}

.alt-background-ten {
  background-image: url("/images/series/burns/burns_3_1.jpg");
}


/* HOVER STATES */

.bar-two:hover ~ .alt-background-two {
  visibility: visible;
  z-index: 1;
}

.bar-three:hover ~ .alt-background-three {
  visibility: visible;
  z-index: 1;
}

.bar-four:hover ~ .alt-background-four {
  visibility: visible;
  z-index: 1;
}

.bar-five:hover ~ .alt-background-five {
  visibility: visible;
  z-index: 1;
}

.bar-six:hover ~ .alt-background-six {
  visibility: visible;
  z-index: 1;
}

.bar-seven:hover ~ .alt-background-seven {
  visibility: visible;
  z-index: 1;
}

.bar-eight:hover ~ .alt-background-eight {
  visibility: visible;
  z-index: 1;
}

.bar-nine:hover ~ .alt-background-nine {
  visibility: visible;
  z-index: 1;
}

.bar-ten:hover ~ .alt-background-ten {
  visibility: visible;
  z-index: 1;
}


.lost-monogram {
  transform: rotateX(90deg) rotate(12deg) translateZ(0) translateY(200px);
}
